<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :profileData="profileData"
      @refetch-data="refetchData"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Tabloda</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>eleman gösteriliyor</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Ara..."
              />
              <b-button
                variant="primary"
                v-if="!innerFlow"
                @click="addUser"
              >
                <span class="text-nowrap">Kişi Ekle</span>
              </b-button>
              <b-link
                class="btn btn-primary ml-1"
                v-if="!innerFlow"
                href="http://giftbee.co/template.csv"
                target="_blank"
              >
                <span class="text-nowrap">Template İndir</span>
              </b-link>
              <b-button
                variant="primary"
                class="ml-1"
                v-if="!innerFlow"
                @click="$refs.refInputEl.$el.click()"
              >
                Yükle
              </b-button>
              <b-form-file
                v-if="!innerFlow"
                ref="refInputEl"
                v-model="profileFile"
                accept=".csv"
                :hidden="true"
                plain
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
      
        <template #cell(checkbox)="data" v-if="innerFlow">
          <b-form-checkbox
            plain
            class="vs-checkbox-con"
            :checked="employee.indexOf(data.item.id) > -1"
            @change="e => $emit('checked', data, e)"
          >
          </b-form-checkbox>
        </template>


        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <template #cell(birthDate)="data">
          <span> {{data.item.birthDate.split('T')[0]}} </span>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(DÜZENLE)="data">
          <feather-icon icon="TrashIcon" @click="deleteEmployee(data)"/>
          <feather-icon icon="EditIcon" @click="editEmployee(data)" class="ml-1"/>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Tabloda {{ dataMeta.from }} dan / den {{ dataMeta.to }} arasındaki kayıtlar gösteriliyor </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormFile, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import axios from '@axios'

export default {
  components: {
    UsersListFilters,
    UserListAddNew,
    BFormFile,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormCheckbox,
    vSelect,
    ToastificationContent
  },
  props: {
    innerFlow: false,
    employee: []
  },
  data() {
    return {
      profileFile: null,
      profileData: {}
    }
  },
  watch: {
    profileFile(oldVal, newVal) {
      this.csvToArray(oldVal)
    }
  },
  methods: {
    addUser(){
      this.profileData = {}
      this.isAddNewUserSidebarActive = true
    },
    async deleteEmployee(data){
      const res = await axios
        .delete(`/api/v1/recipients/${data.item.id}`)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Kişi Başarı ile silindi',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.refetchData()
        })
        .catch(error => console.log(error))
    },
    editEmployee(data){
     this.isAddNewUserSidebarActive = true
     this.profileData = data
    },
    csvToArray(oldVal, delimiter = ";") {
        let reader = new FileReader();
        reader.readAsText(oldVal, 'ISO-8859-1');
        reader.onload = async function (e, toast) {
          const text = e.target.result;
          const headers = text.slice(0, text.indexOf("\r")).split(delimiter);

          const rows = text.slice(text.indexOf("\n") + 1).split("\n");

          const arr = rows.map(function (row) {
            const values = row.split(delimiter);
            const el = headers.reduce(function (object, header, index) {
              object[header] = values[index]
              return object;
            }, {});
            return el;
          });

          // return the array
          const res = await axios
            .post(`/api/v1/recipients`, {recipients : arr})
            .then(response => {
              e.target.value = ""
              e.target.result = ""
            })
            .catch(error => console.log(error))
        };
        reader.addEventListener('loadend', () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Kişiler Başarı ile eklendi',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.refetchData()
        });
        
      }
  },
  setup(props) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })


    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Pending', value: 'pending' },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useUsersList()
    
    if(props.innerFlow) tableColumns.unshift({key:'checkbox', label: ''})

    return {

      // Sidebar
      isAddNewUserSidebarActive,
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
