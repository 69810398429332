<template>
  <div>
    <form-wizard
      v-if="!finish"
      ref="wizard"
      color="#e29134"
      :title="null"
      :subtitle="null"
      :start-index="steps"
      layout="vertical"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="wizard-vertical mb-3"
      backButtonText="Geri"
      nextButtonText="İleri"
      finishButtonText="Ödemeyi Yap"
      @on-change="formSubmitted"
      @on-validate="formValidate"
      @on-complete="complete"
    >
      <!-- account datails tab -->
      <!-- Hediyenin geçerli olacağı tarih, açıklama, ismi -->
      <tab-content title="Hediye Tanımla" :before-change="validateFirstForm">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Hediye Tanımla</h5>
            <small class="text-muted">
              Hediye Tanımlayarak devam edebilirsin.
            </small>
          </b-col>
          <b-col md="6">
            <b-form-group label="Kimden" label-for="i-username">
              <b-form-input
                id="i-username"
                v-model="from"
                placeholder="Hediye kimden gönderilsin?"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Hediye grubu" label-for="i-username">
              <b-form-input
                id="i-username"
                v-model="giftName"
                placeholder="Hediye grubu giriniz"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label class="font-weight-bold">
                Hediyenin başlangıç tarihini seçiniz
              </label>
              <flat-pickr v-model="dateDefault" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Hediye notu" label-for="i-username">
              <b-form-input
                id="i-username"
                v-model="giftDesc"
                placeholder="Hediye notu bırakınız"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label class="font-weight-bold">
                Hediye seçiminin biteceği tarihi seçiniz
              </label>
              <flat-pickr v-model="endDate" class="form-control" />
            </b-form-group>
          </b-col>
        </b-row>
      </tab-content>

      <!-- kullanıcı idlerini yollayacağız -->

      <!-- personal info tab -->
      <tab-content title="Kişileri Seç" :before-change="validationForm">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Göndereceğin kişiler</h5>
            <small class="text-muted"
              >Hediyeyi göndereceğin kişileri seçerek devam edebilirsin.</small
            >
          </b-col>
          <div class="w-100 p-1">
            <UserList
              :innerFlow="true"
              @checked="changeStatus"
              :employee="employeeIds"
            />
          </div>
        </b-row>
      </tab-content>

      <!-- product id yollayacağız -->

      <!-- address -->
      <tab-content title="Bir mesaj yolla" :before-change="validationForm">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Hadi bir mesaj yollayalım</h5>
            <small class="text-muted"
              >Hediyeyi göndereceğin kişilere video ve ses kaydı gönderebilirsin.</small
            >
          </b-col>
          <div class="w-100 p-1">
            <b-card-text>
              <span
                >Göndermek istediğin dosyayı buraya sürükle
              </span>
            </b-card-text>
            
            <div v-if="file1.startsWith('https')" class="d-flex">
              <b-form-input
                id="i-username"
                v-model="file1"
                disabled
                placeholder="Hediye kimden gönderilsin?"
              />
              <b-button
                variant="primary"
                class="btn-wishlist ml-2"
                @click="e => file1=''"
              >
                Sil
              </b-button>
            </div>

            <b-form-file
              v-if="!file1.startsWith('https')"
              v-model="file1"
              accept=".jpg, .mp3, .mp4"
              placeholder="Göndermek istediğin dosyayı buraya sürükle ve bırak..."
              drop-placeholder="Drop file here..."
            />
          </div>
        </b-row>
      </tab-content>

      <tab-content title="Hediyeleri seç" :before-change="validationProduct">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Ürün seç</h5>
            <small class="text-muted"
              >Yollayacağın ürünleri seçerek devam et.</small
            >
          </b-col>
          <b-col cols="12">
            <portal-target name="content-renderer-sidebar-detached-left" slim />
            <div class="commerce">
              <CommerceShop
                :innerFlow="true"
                @checked="selectedProduct"
                :selectedProduct="productIds"
              />
            </div>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Ödemeyi Yap">
        <b-row>
          <b-col cols="12" class="mb-2">
            <h5 class="mb-0">Ödemeyi Yap</h5>
            <small class="text-muted">Ödemeyi yaparak hediyeni tamamla!</small>
          </b-col>
          <div class="d-flex flex-column ml-1 payment">
            <p>
              Hediyeler arasından en yüksek ücretli hediyen
              <span>{{ maxPrice }}</span> tl
            </p>
            <p>
              Bakiyenden blokle edilecek ücret:
              <span>{{ lockedAmount }}</span> tl
            </p>
            <p>
              {{
                error.length ? error.map((item) => item.message).toString() : ""
              }}
            </p>
          </div>
        </b-row>
      </tab-content>
    </form-wizard>
    <div v-else>
      <Finish />
    </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { BRow, BCol, BFormGroup, BFormInput,  BFormFile, BCardText, BButton  } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import UserList from "../../apps/user/users-list/UsersList";
import axios from "@axios";
import CommerceShop from "../../apps/e-commerce/e-commerce-shop/ECommerceShop";
import Finish from "../../card/card-advance/CardAdvanceCongratulation.vue";

import store from "@/store";

export default {
  components: {
    BButton,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    UserList,
    CommerceShop,
    Finish,
    BCardCode,
    BFormFile,
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      selectedContry: "select_value",
      selectedLanguage: "nothing_selected",
      file1: "",
      giftName: "",
      giftDesc: "",
      dateDefault: "",
      endDate: "",
      employeeIds: [],
      productIds: [],
      steps: 0,
      status: "",
      finish: false,
      giftId: "",
      lockedAmount: "",
      maxPrice: "",
      error: [],
      from: "",
    };
  },
  async mounted() {
    this.from = JSON.parse(localStorage.getItem("userData")).company;
    await axios
      .get(`/api/v1/gift-designs`)
      .then((response) => {
        if (response.data.data.length > 0) {
          const gift = response.data.data[0];
          this.giftName = gift.name;
          this.giftDesc = gift.description;
          this.employeeIds = gift.recipients.map((item) => item.id);
          this.productIds = gift.products.map((item) => item.id);
          this.dateDefault = gift.startDate;
          this.status = gift.status;
          this.giftId = gift.id;
          this.from = gift.from;
          this.file1 = gift.media[0]
          if (this.status === "Initialized") {
            this.$refs.wizard.changeTab(0, 1);
          }
          if (this.status === "RecipientsSelected") {
            this.$refs.wizard.changeTab(0, 1);
            this.$refs.wizard.changeTab(1, 2);
          }
          if (this.status === "MessageSelected") {
            this.$refs.wizard.changeTab(0, 1);
            this.$refs.wizard.changeTab(1, 2);
            this.$refs.wizard.changeTab(2, 3);
          }
          if (this.status === "ProductsSelected") {
            this.$refs.wizard.changeTab(0, 1);
            this.$refs.wizard.changeTab(1, 2);
            this.$refs.wizard.changeTab(2, 3);
            this.$refs.wizard.changeTab(3, 4);
          }
          if (this.status === "Finalized") {
            this.finish = true;
          }
        }
      })
      .catch((error) => console.log(error));
  },
  methods: {
    validationProduct() {
      if (this.productIds.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Lütfen ürün veya ürünleri seçiniz",
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
      return this.productIds.length !== 0;
    },
    validationForm() {
      if (this.employeeIds.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Lütfen Göndereceğiniz kişi veya kişileri seçiniz",
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
      return this.employeeIds.length !== 0;
    },
    async validateFirstForm() {
      if (!this.giftName || !this.giftDesc || !this.dateDefault) {
        this.$refs.wizard.prevTab();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Alanlar boş bırakılamaz",
            icon: "EditIcon",
            variant: "warning",
          },
        });
      }
      const data = {
        name: this.giftName,
        description: this.giftDesc,
        startDate: this.dateDefault,
        endDate: this.endDate,
        from: this.from,
      };
      const res = await axios
        .post(`/api/v1/gift-designs`, data)
        .then((response) => {
          this.giftId = response.data.data;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.errors[0].message,
              icon: "EditIcon",
              variant: "warning",
            },
          });
          return false;
        });
      return this.giftName && this.giftDesc && this.dateDefault && true;
    },
    formValidate(validationResult, activeTabIndex) {
      console.log(validationResult, activeTabIndex);
    },
    async formSubmitted(current, next) {
      if (current === 1 && next === 2) {
        axios
          .put(`/api/v1/gift-designs/${this.giftId}/recipients`, {
            recipientIds: this.employeeIds,
          })
          .then((response) => console.log(response))
          .catch((error) => console.log(error));
      }
      if (current === 2 && next === 3) {
        if(typeof this.file1 === 'string' && this.file1 !== "") return
        const formData = new FormData()
        formData.append('files',this.file1)
        console.log(formData, this.file1.name, this.file1)
        await axios
          .post(`/api/v1/gift-designs/${this.giftId}/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then((response) => console.log(response))
          .catch((error) => console.log(error));
      }
      if (current === 3 && next === 4) {
        axios
          .put(`/api/v1/gift-designs/${this.giftId}/products`, {
            productIds: this.productIds,
          })
          .then((res) => {
            this.maxPrice = res.data.data.maxPrice;
            this.lockedAmount = res.data.data.lockedAmount;
          })
          .catch((err) => {
            if (err.response) this.error = err.response.data.errors;
          });
      }
    },
    complete() {
      axios
        .post(`/api/v1/gift-designs/${this.giftId}/finalize`)
        .then((res) => {
          if (!res.data?.errors?.length) {
            store.dispatch("wallet-store/fetchWallet");
            this.finish = true;
          } else {
            this.error = res.data.errors;
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Lütfen bakiye ekleyiniz`,
                text: `Hediyeyi göndermek için bakiyene ek ${err.response.data.errors[0].errorMeta.RequiredAmount} TL gerekmektedir`,
                icon: "EditIcon",
                variant: "warning",
              },
            });
          }
        });
    },
    changeStatus(e, type) {
      if (type) {
        this.employeeIds.push(e.item.id);
      } else {
        this.employeeIds = this.employeeIds.filter((i) => e.item.id !== i);
      }
    },
    selectedProduct(e, id) {
      console.log(e);
      if (!e) this.productIds = this.productIds.filter((item) => item !== id);
      else this.productIds.push(id);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.commerce {
  .ecommerce-header-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .view-options {
    display: none !important;
  }
  .grid-view {
    display: flex !important;
    flex-flow: wrap;
    padding-left: 15px;
    & > div {
      margin-right: 15px;
      max-width: 300px;
    }
  }
  .ecommerce-searchbar {
    margin-bottom: 15px;
  }
}
.payment {
  & > p > span {
    font-size: 20px;
  }
}
</style>
